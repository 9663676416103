<template>
  <div v-if="vehicle" id="VehicleDetail" class="container-fluid container-xl pt-lg-4">
    <h1 v-show="false">
      {{ vehicle.Year }} {{ vehicle.Make }} {{ vehicle.Model }}
      {{ vehicle.Trim }} Electric Vehicle Details
    </h1>
    <div class="row d-lg-none mobile-nav" :class="{ scrolled: hasScrolled }">
      <div class="col-12">
        <div class="row mobile-ymms">
          <div class="col-12">
            <h2>
              {{ vehicle.Year }} {{ vehicle.Make }} {{ vehicle.Model }}
              {{ vehicle.Trim }}
            </h2>
          </div>
        </div>
        <div class="row mobile-links d-lg-none pt-2" v-show="hasScrolled">
          <div class="col-3 mx-0 px-0" v-for="link in featureLinks" :key="link.id">
            <router-link :to="{ hash: `#${link.id}` }">{{ link.name }} &raquo;</router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="row d-none d-lg-block" v-show="searchOptions">
      <div class="col-12 px-0">
        <SearchCriteriaNav></SearchCriteriaNav>
      </div>
    </div>
    <div class="row">
      <div class="col-12 vehicle-detail align-content-center">
        <div class="row">
          <div class="col-12">
            <div id="carousel-container" class="row">
              <div class="vehicle-summary col-12 col-lg-4 order-2 order-lg-1">
                <div class="row ymms d-none d-lg-block">
                  <div class="col-12 pr-0">
                    <span class="year">{{ vehicle.Year }}</span>&nbsp; <span class="make">{{ vehicle.Make }}</span>&nbsp;
                    <span class="model">{{ vehicle.Model }}</span>&nbsp;
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label class="d-block" for="TrimSelect">Selected Trim Level</label>
                    <select id="TrimSelect" v-model="selectedTrim" @change="goToTrim()">
                      <option v-for="ymms in trims" :key="ymms.YMMS_Desc" :value="ymms">{{ ymms.Trim || 'Base' }}</option>
                    </select>
                  </div>
                </div>
                <div class="row px-3 pb-3 pt-2">
                  <div class="col pr-0">
                    <a :href="vehicle.Build_Url" class="build-and-price-link" :disabled="!vehicle.Build_Url">
                      <div class="row">
                        <div class="col-auto px-0 mx-0 pt-1"><i class="fas fa-cart-arrow-down"></i></div>
                        <div class="col">Build
                          &
                          Price&nbsp;<span>&raquo;</span></div>
                      </div>
                    </a>
                  </div>
                  <div class="col pr-0">
                    <a :href="vehicle.Dealer_Url" class="find-dealer-link" :disabled="!vehicle.Dealer_Url">
                      <div class="row">
                        <div class="col-auto px-0 mx-0 pt-1"><i class="fas fa-search-location"></i></div>
                        <div class="col">Find a Dealer&nbsp;<span>&raquo;</span></div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="row summary-metrics">
                  <div :class="idx === 0 ? 'col-12' : 'col-6'" class="pr-0" v-for="(metric, idx) in summaryMetrics"
                    :key="'A' + idx">
                    <div class="summary-panel">
                      <label class="d-block">{{ metric.label }}</label>
                      <span>
                        <VehicleMetric :vehicle="vehicle" :metric="metric" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-8 px-0 order-1 order-lg-2">
                <b-carousel id="carousel" class="text-right" :interval="0" :controls="imagesToShow.length > 1">
                  <!-- Text slides with image -->
                  <b-carousel-slide v-for="image in imagesToShow" :key="image.type">
                    <template v-slot:img>
                      <VehicleImage :vehicle="vehicle" :imgType="image.type" imgSizeType="Wide"
                        @image-not-found="imgNotFound($event, image)" />
                      <h4 class="image-type-label">
                        {{
                          image.type.charAt(0).toUpperCase() +
                          image.type.slice(1).replace("2", "")
                        }}
                      </h4>
                    </template>
                  </b-carousel-slide>
                </b-carousel>
              </div>
            </div>
            <div class="row mobile-links d-lg-none mb-4" v-show="!hasScrolled">
              <div class="col-3 mx-auto my-1" v-for="link in featureLinks" :key="link.id">
                <router-link :to="{ hash: `#${link.id}` }">{{ link.name }}&nbsp;&raquo;</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="row text-center sub-nav d-none d-lg-flex">
          <div class="col" v-for="link in featureLinks" :key="link.id">
            <router-link :to="{ hash: `#${link.id}` }">{{ link.name }}&nbsp;<span
                class="accent">&raquo;</span></router-link>
          </div>
        </div>
        <!-- <EbayListings v-if="vehicle" :vehicle="vehicle" /> -->
        <div id="InfoPanel" class="row d-none d-lg-block">
          <div class="col-12">
            <div class="row">
              <div class="col">
                <h2>Owning a {{ vehicle.Make }} {{ vehicle.Model }}...</h2>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-6">
                <h3>Pros</h3>
                <ul>
                  <li v-for="pro in prosCons.pros" :key="pro">
                    {{ pro }}
                  </li>
                </ul>
              </div>
              <div class="col-6">
                <h3>Cons</h3>
                <ul>
                  <li v-for="con in prosCons.cons" :key="con">
                    {{ con }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-5 mt-2">
          <div class="col">
            <CostToDrivePanel :vehicle="vehicle" />
          </div>
        </div>

        <!-- <CostDetail :vehicle="vehicle" class="mt-5 mt-lg-0"></CostDetail> -->
        <div class="row mb-5">
          <div class="col-12 col-lg-6 pb-5">
            <PerformancePanel :vehicle="vehicle" />
          </div>
          <div class="col-12 col-lg-6 pb-5">
            <SafetyPanel :vehicle="vehicle" />
          </div>
        </div>
        <div class="row mb-5">
          <div class="col">
            <InfotainmentPanel :vehicle="vehicle" />
          </div>
        </div>
        <EnvironmentalImpact :vehicle="vehicle" />
        <SolarPanel />

        <div class="row mb-5">
          <div class="col-12 col-lg-6 pb-5">
            <ReliabilityPanel :vehicle="vehicle" />
          </div>
          <div class="col-12 col-lg-6 pb-5">
            <CargoPanel :vehicle="vehicle" />
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-12 col-lg-6 pb-5">
            <ChargingPanel :vehicle="vehicle" />
          </div>
        </div>
        <EquipmentDetail :vehicle="vehicle"></EquipmentDetail>
      </div>
      <div id="AccuracyFooter" class="container-fluid py-4">
        <div class="row">
          <div class="col-12 col-lg-6 ml-4">
            <AccuracyDisclaimer />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { FeatureMap } from "Services/FeatureMap";
import { Constants } from "Services/Constants";
import ElectricDriverApi from "Services/ElectricDriverApi";
import common from "Mixins/common";

const VehicleMetric = () => import("Components/VehicleMetric");
const EquipmentDetail = () => import("Components/EquipmentDetail");
const EnvironmentalImpact = () => import("Components/EnvironmentalImpact");
const SolarPanel = () => import("Components/SolarPanel");
const VehicleImage = () => import("Components/VehicleImage");
const AccuracyDisclaimer = () => import("../components/AccuracyDisclaimer");
const SearchCriteriaNav = () => import("Components/SearchCriteriaNav");
const PerformancePanel = () =>
  import("Components/VehicleDetail/PerformancePanel.vue");
const SafetyPanel = () => import("Components/VehicleDetail/SafetyPanel.vue");
const InfotainmentPanel = () =>
  import("Components/VehicleDetail/InfotainmentPanel.vue");
const ReliabilityPanel = () =>
  import("Components/VehicleDetail/ReliabilityPanel.vue");
const CargoPanel = () => import("Components/VehicleDetail/CargoPanel");
const ChargingPanel = () => import("Components/VehicleDetail/ChargingPanel");
const CostToDrivePanel = () =>
  import("Components/VehicleDetail/CostToDrivePanel");

export default {
  metaInfo() {
    if (this.vehicle) {
      return {
        title: `${this.vehicle.Year} ${this.vehicle.Make} ${this.vehicle.Model} ${this.vehicle.Trim}`,
        script: [
          {
            type: "application/ld+json",
            json: {
              "@context": "https://schema.org",
              "@type": "Car",
              name: this.vehicle.Model,
              manufacturer: this.vehicle.Make,
              vehicleConfiguration: this.vehicle.Trim,
            },
          },
        ],
        meta: [
          {
            name: "description",
            content: `See how the ${this.vehicle.Year} ${this.vehicle.Make} ${this.vehicle.Model} ${this.vehicle.Trim} fits your needs. See pricing, the cost to drive, and learn the benefits of EV ownership.`,
          },
        ],
      };
    }
    return {
      title: "Electric car details",
      meta: [
        {
          name: "description",
          content:
            "See how this electric car fits your needs. See pricing, the cost to drive, and learn the benefits of EV ownership.",
        },
      ],
    };
  },
  props: ["id", "ymms_desc", "lat", "lng"],
  components: {
    VehicleMetric,
    EquipmentDetail,
    SolarPanel,
    VehicleImage,
    SearchCriteriaNav,
    EnvironmentalImpact,
    PerformancePanel,
    SafetyPanel,
    InfotainmentPanel,
    ReliabilityPanel,
    CargoPanel,
    ChargingPanel,
    CostToDrivePanel,
    AccuracyDisclaimer
  },
  async mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("touchend", this.handleScroll);
    await Promise.all([this.getVehicle(), this.getLocation()]);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("touchend", this.handleScroll);
  },
  data() {
    //Get params from route or store
    let summaryMetrics = this.getSummaryMetrics();
    let featureGroups = this.getFeatureGroups();
    this.$store.dispatch('fetchAllYMMS')
    const featureLinks = [
      { name: "Performance", id: "PerformancePanel" },
      { name: "Infotainment", id: "InfotainmentPanel" },
      { name: "Environment", id: "Environment" },
      { name: "Safety", id: "SafetyPanel" },
      { name: "Reliability", id: "ReliabilityPanel" },
      { name: "Cargo", id: "CargoPanel" },
      { name: "Charging", id: "ChargingPanel" },
    ];

    let images = this.getImages();

    return {
      vehicle: null,
      summaryMetrics,
      featureGroups,
      prosCons: null,
      images,
      featureLinks,
      scrollY: window.scrollY,
      selectedTrim: null
    };
  },
  methods: {
    handleScroll() {
      this.scrollY = window.scrollY;
    },
    async getLocation() {
      let coords = {
        lat: this.lat,
        lng: this.lng,
      };
      if (!coords.lat || !coords.lng) {
        coords = this.location?.address?.coordinates;
      }

      if (
        !this.location?.address?.zip ||
        this.location?.address?.coordinates?.lat != coords.lat ||
        this.location?.address?.coordinates?.lng != coords.lng
      ) {
        this.$store.commit(
          "loading",
          "Getting local rates and charging locations..."
        );
        await this.$store
          .dispatch("fetchLocationInfo", coords)
          .finally(() => this.$store.commit("loaded"));
      }
    },
    async getVehicle() {
      let vehicle = null;
      this.$store.commit("loading", "Getting your vehicle...");
      if (this.id) {
        vehicle = await ElectricDriverApi.getVehicleById(this.id);
      } else {
        vehicle = await ElectricDriverApi.getVehicleByYMMS(this.ymms_desc);
      }
      this.setVehicle(vehicle);
      this.selectedTrim = this.getCurrentTrim()
      this.$store.commit("loaded");
    },
    setVehicle(vehicle) {
      if (!vehicle) this.$router.push("/");

      this.prosCons =
        vehicle.ProsCons?.cons?.length || vehicle.ProsCons?.pros?.length
          ? vehicle.ProsCons
          : vehicle.Make === "Tesla"
            ? Constants.prosCons.Tesla
            : Constants.prosCons.Other;

      this.vehicle = vehicle;
    },
    imgNotFound($event, image) {
      if (image.type !== "exterior") {
        image.show = false;
      }
    },
    getSummaryMetrics() {
      return this.getFeature("VehicleDetailSummary").metrics;
    },
    getCurrentTrim() {
      return this.$store.state.allYmms?.filter((x) => {
        return x.Year === this.vehicle.Year && x.Make === this.vehicle.Make && x.Model === this.vehicle.Model && x.Trim === this.vehicle.Trim
      })[0]
    },
    getFeature(featureName) {
      return FeatureMap.find((f) => f.name === featureName);
    },
    getFeatureGroups() {
      const featureGroupNames = [["Value"], ["Cargo", "Charging"]];
      let featureGroups = [];
      featureGroupNames.forEach((f) => {
        let group = [];
        f.forEach((f2) => {
          group.push(this.getFeature(f2));
        });
        featureGroups.push(group);
      });
      return featureGroups;
    },
    getImages() {
      let imgs = [{ type: "exterior", show: true }];
      const imgTypes = ["interior", "interior2", "trunk", "seating"];

      imgTypes.forEach((x) => {
        imgs.push({ type: x, show: true });
      });
      return imgs;
    },
    async goToTrim() {
      try {
        await this.$router.push({
          name: "detail_ymms_desc",
          params: {
            ymms_desc: this.selectedTrim.YMMS_Desc
          },
        });
        await this.getVehicle();
      } catch (error) {
        console.error(error);
      }
    }
  },
  computed: {
    imagesToShow() {
      return this.images.filter((x) => x.show === true);
    },
    searchOptions() {
      return this.$store.state.searchOptions;
    },
    location() {
      return this.$store.state.locationInfo;
    },
    hasScrolled() {
      return this.scrollY > 35;
    },
    trims() {
      return this.$store.state.allYmms?.filter((x) => {
        return x.Year === this.vehicle.Year && x.Make === this.vehicle.Make && x.Model === this.vehicle.Model
      }) || []
    }
  },
  mixins: [common],
};
</script>

<style scoped lang="scss">
#VehicleDetail {
  color: $primary-font-color-dark;
}

h2 {
  color: $secondary-font-color-dark;
  font-weight: 800;
}

.ymms {
  background-color: #f9f9f9;
  font-family: $primary-header-font-family;
  font-size: 1.5rem;
  padding-top: 25px;

  .year {
    color: $primary-font-color-dark;
    font-weight: 800;
  }

  .make {
    color: $secondary-font-color-dark;
    font-weight: 800;
  }

  .model {
    font-weight: normal;
  }

  .trim {
    font-size: 0.8rem;
  }
}

.vehicle-detail {
  padding-top: 20px;
  padding-bottom: 60px;
  background: white;

  @include media-breakpoint-down(md) {
    padding-top: 0;
  }
}

#carousel-container {
  @include media-breakpoint-down(md) {
    border-top: 0;
    padding-bottom: 0;
  }

  border-top: 2px solid white;
  background-color: #f9f9f9;

  #carousel {
    h4.image-type-label {
      color: white;
      text-shadow: 0 0 5px black;
      margin-top: -30px;
      padding-right: 20px;
    }

    & ::v-deep .carousel-control-prev-icon {
      position: relative;
      left: -25%;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2330ca34' viewBox='0 0 8 8' %3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
    }

    & ::v-deep .carousel-control-next-icon {
      position: relative;
      right: -25%;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2330ca34' viewBox='0 0 8 8' %3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
    }

    & ::v-deep .carousel-indicators li {
      background-color: $primary-bg-color;

      &:not(.active) {
        opacity: 0.25;
      }
    }

    & ::v-deep img {
      margin: auto;
      width: 100%;
    }
  }

  padding-bottom: 0;
}

.summary-metrics {
  border-top: 10px solid white;
  height: 287px;

  @include media-breakpoint-down(md) {
    border-top: none;
  }
}

.vehicle-summary {
  padding-top: 0;
  font-size: 1.1rem;
  margin-top: 0;
  font-weight: 500;

  .col-6:nth-of-type(even) .summary-panel {
    border-right: 1px solid #e0e0e0;
    box-shadow: 5px 0px 0px 0px rgba(0, 0, 0, 0.02);
    box-sizing: border-box;
  }

  .summary-panel {
    padding: 20px 10px 7px 7px;
  }

  label {
    font-size: 0.8125rem;
    font-weight: 800;
    color: #858585;
    margin-bottom: 0;
    font-family: $primary-header-font-family;
  }

  span {
    color: $primary-font-color-dark;
  }

  ::v-deep span.accent {
    font-size: 0.8rem;
  }

  .ebay-button {
    padding: 20px 30px 20px 30px;
    background-color: $light-bg-color;
    color: #666666;
    border: 2px solid #9a9a9a;
    border-radius: 10px;
    font-size: 0.75rem;
    font-weight: bold;

    h3 {
      font-size: 1rem;
    }

    p {
      margin-bottom: 0;
    }

    &:hover {
      text-decoration: none;
    }
  }

  @include media-breakpoint-down(sm) {
    label {
      font-size: 1rem;
    }

    span {
      font-size: 1.25rem;
    }

    ::v-deep span.accent {
      font-size: 0.7rem;
    }
  }
}

#InfoPanel {
  padding: 20px 7px 10px 7px;
  background-image: url("~Images/blurred_pattern_bg.png");
  background-repeat: repeat-y;
  background-size: 100% auto;
  border-top: 3px solid #2b82e3;
  border-bottom: 3px solid #2b82e3;
  margin-bottom: 15px;

  h2,
  h3,
  li {
    color: white;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1rem;
  }

  li {
    padding-bottom: 5px;
    list-style-image: url("~Images/leaf_bullet.png");
  }

  .btn {
    max-width: 150px;
    padding: 1px;
    font-size: 0.8rem;
  }
}

.count-icons {
  padding-left: 5px;

  i.fas {
    padding-right: 3px;
  }
}

::v-deep .subheader {
  border-top: 1px solid $primary-bg-color;

  h4 {
    font-size: 16px;
    margin-top: -10px;
    background-color: white;
    padding-right: 10px;
    padding-left: 10px;
    color: $secondary-font-color-dark;
    font-weight: 800;
  }
}

.sub-nav {
  z-index: 1000;
  background-color: #edf2f5;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 0;
  margin-bottom: 0;

  a {
    padding: 3px 15px 2px 15px;
    border: 1px solid #cfcfcf;
    color: #3c68ca;
    background-color: white;
    font-size: 0.75rem;

    .accent {
      color: black;
    }
  }
}

::v-deep .search-criteria.nav {
  padding-left: 15px !important;

  .display-only {
    color: $link-color-dark;
  }
}

.mobile-nav {
  h2 {
    font-size: 1.5rem;
    color: black;
  }

  &.scrolled {
    margin-top: 0px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }

  .mobile-ymms {
    padding: 20px 0 10px 10px;
    background-color: #a9c5d1;
  }
}

.mobile-links {
  background-color: #edf2f5;
  padding-right: 10px;
  padding-left: 20px;

  a {
    font-size: 0.75rem;
    text-decoration: underline;
  }
}

::v-deep #SearchPopup {
  @include media-breakpoint-up(xl) {
    position: fixed;
  }

}

#AccuracyFooter {
  background-color: $striped-row-bg-color;
}

#TrimSelect {
  width: 100%;
}
</style>
